import React from "react";

function FAQ() {
  return (
    <div className="row m-0 pt-5" id="FAQs">
      <h3 className="text-center py-4 mt-5">Frequently Asked Questions</h3>
      <div className="col-12 col-md-6 text-center">
        <img src="assets/faq.png" alt="FAQ" className="img-fluid w-75 " />
      </div>
      <div className="col-12 col-md-6">
        <div className="accordion px-3 " id="accordionExample">
          <div className="accordion-item border-0 mt-5 mb-3 shadow ">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h5>
                Why should you join the Learn and Build Summer Training 2025 Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>The <b>Learn and Build Summer Training 2025</b> provides <b>hands-on experience, real-world projects, industry-relevant skills, and expert mentorship.</b> It’s a perfect opportunity to <b>enhance your technical knowledge, boost your resume, and prepare for future career opportunities</b> in the tech industry.</p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h5>
                Who can apply for the Summer Training Program 2025?
                </h5>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              <p>
              This program is open to <b>students, graduates, and working professionals</b> interested in <b>learning new technologies</b> or upgrading their skills. No prior experience is required for Level 1 courses, while Level 2 and 3 require basic knowledge of programming or relevant technologies.
              </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h5>Who will be the trainers?</h5>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                The sessions will be conducted by <b>industry experts, experienced trainers, and professionals</b> working in top tech companies. You will also have access to <b>mentorship sessions</b> from industry leaders.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <h5>
                How will this Project-Based Summer Industrial Training & Internship benefit me?
                </h5>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                <li><b>Learn industry-demanded skills</b> through project-based training</li>
                <li>Work on <b>real-world projects</b> to strengthen practical knowledge</li>
                <li><b>Get an Internship Certificate</b> to boost your resume</li>
                <li>Improve <b>problem-solving and technical skills</b></li>
                <li>Gain exposure to <b>industry trends and innovations</b></li>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <h5>
                What is the duration of the Summer Industrial Training Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                <li><b> ✅ Level 1</b> – 45 hours (15-20 Days)</li>
                <li><b>✅ Level 2 </b>– 60 hours (30-45 Days)</li>
                <li><b>✅ Level 3 </b> – 90/120 hours (60-90 Days)</li>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <h5>
                Is there a fee for the Summer Industrial Training Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              <p>Yes, the fee structure is as follows:
                <br />
                <br />
                <li><b>Level 1</b> – INR 2,000</li>
                <li><b>Level 2</b> – INR 8,000</li>
                <li><b>Level 3</b> – INR 12,000</li>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseseven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <h5>
                Can I choose between online and physical mode for the Summer Industrial Training?</h5>
              </button>
            </h2>
            <div
              id="collapseseven"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                Yes! The program offers <b>both online and offline (physical) training modes.</b> You can select the mode that best fits your learning preference and availability.
                </p>
              </div>
            </div>
          </div> */}

          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <h5>
                What happens in the Daily Interactive Sessions?</h5>
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                The <b>daily interactive sessions</b> focus on <b>practical skill development</b>, hands-on guidance, live demonstrations, and problem-solving. These sessions ensure that you <b>apply what you learn</b> effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsnine"
                aria-expanded="false"
                aria-controls="collapsnine"
              >
                <h5>
                How often are Industry Mentor Sessions conducted?</h5>
              </button>
            </h2>
            <div
              id="collapsnine"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                Industry Mentor Sessions are conducted <b>at scheduled intervals</b> and are not daily. These sessions feature <b>experts from leading tech companies</b> who share insights on industry trends, career growth, and skill development
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
