import React from "react";
import { Card, Col, Row } from "antd";

export default function Level({ Level }) {
  // Fixing filtering issue
  const groupLevels = {
    one: Level.filter((item) => item.level === "one"),
    two: Level.filter((item) => item.level === "two"),
    three: Level.filter((item) => item.level === "three"), // Fix "three" typo
  };

  return (
    <div style={{ padding: "20px" }}>
      {Object.entries(groupLevels).map(([key, levels]) => (
        levels.length > 0 && ( // Prevent rendering empty sections
          <div key={key} style={{ marginBottom: "30px" }}>
            <h2 style={{ textAlign: "center" }}>LEVEL {key.toUpperCase()}</h2>
            <h6>
                <b>
            <p style={{ textAlign: "center" }}>
              {key === "one" && "15-20 Days | 45 Hours"}
              {key === "two" && "30-35 Days | 60 Hours"}
              {key === "three" && "60-90 Days | 120 Hours"}
            </p>
            </b>
            </h6>
            <Row gutter={[16, 16]}>
              {levels.map((level, index) => (
                <Col span={8} key={index}>
                  <Card
                    hoverable
                    style={{ width: 300 }}
                    cover={<img alt={level.name} src={level.image} />}
                  >
                    <h5><b>{level.name}</b></h5>
                    <p>{level.description}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )
      ))}
    </div>
  );
}
