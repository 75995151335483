import React from "react";
import ScrollIntoView from "react-scroll-into-view";

function Hero() {
  return (
    <>
      {/* Desktop Carousel */}
      <div
        id="carouselExampleDesktop"
        className="carousel slide d-md-block d-none"
        data-bs-ride="carousel"
        data-bs-touch="true"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDesktop"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDesktop"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDesktop"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <ScrollIntoView selector="#formRegister">
              <img
                // src="/assets/1landscapeweb.png"
                src="/assets/Carousel/Banner1Horizonatl2025.png"
                className="w-100"
                alt="Slide 1"
              />
            </ScrollIntoView>
          </div>
          <div className="carousel-item">
            <ScrollIntoView selector="#formRegister">
              <img
                // src="/assets/2horizantal.png"D:\NETParam\LNQ\public\assets\
                src="/assets/Carousel/Banner2Horizonatl2025.png"
                className="w-100"
                alt="Slide 2"
              />
            </ScrollIntoView>
          </div>
          <div className="carousel-item">
            <ScrollIntoView selector="#formRegister">
              <img
                // src="/assets/3landscapeWeb.png"
                src="/assets/Carousel/Banner 2.png"
                className="w-100"
                alt="Slide 3"
              />
            </ScrollIntoView>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDesktop"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDesktop"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Mobile Carousel */}
      <div
        id="carouselExampleMobile"
        className="carousel slide d-md-none d-block"
        data-bs-ride="carousel"
        data-bs-touch="true"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleMobile"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleMobile"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleMobile"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              // src="/assets/1landscapMobile.png"
              src="/assets/Carousel/BannerMob1_2025.png"
              className="w-100"
              alt="Slide 1"
            />
          </div>
          <div className="carousel-item">
            <img
              src="/assets/Carousel/BannerMob2_2025.png"
              className="w-100"
              alt="Slide 2"
            />
          </div>
          <div className="carousel-item">
            <img
              src="/assets/Carousel/Summer vertical 3.jpg"
              className="w-100"
              alt="Slide 3"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleMobile"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleMobile"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default Hero;














// import React from "react";
// import ScrollIntoView from "react-scroll-into-view";

// function Hero() {
//   return (
//     <>
//       <div
//         id="carouselExampleDesktop"
//         className="carousel slide d-md-block d-none"
//         data-bs-ride="carousel"
//         data-bs-touch="true"
//       >
//         <div className="carousel-indicators">
//           <button
//             type="button"
//             data-bs-target="#carouselExampleDesktop"
//             data-bs-slide-to="0"
//             className="active"
//             aria-current="true"
//             aria-label="Slide 1"
//           ></button>
//           {/* <button
//             type="button"
//             data-bs-target="#carouselExampleDesktop"
//             data-bs-slide-to="1"
//             aria-label="Slide 2"
//           ></button> */}
// {/* my code */}
// <button
//       type="button"
//       data-bs-target="#carouselExampleDesktop"
//       data-bs-slide-to="1"
//       aria-label="Slide 2"
//     ></button>
//     <button
//       type="button"
//       data-bs-target="#carouselExampleDesktop"
//       data-bs-slide-to="2"
//       aria-label="Slide 3"
//     ></button>
// {/* added */}
//         </div>
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <ScrollIntoView selector="#formRegister">
//               <img
//                 src="/assets/1landscapeweb.png"
//                 className="w-100 d-md-block d-none"
//                 alt="Slide 1"
//               />
//             </ScrollIntoView>
//           </div>
//           {/* <div className="carousel-item">
//             <ScrollIntoView selector="#formRegister">
//               <img
//                 src="/assets/BannerPC2.webp"
//                 className="d-md-block d-none w-100"
//                 alt="Slide 2"
//               />
//             </ScrollIntoView>
//           </div> */}

//    {/* my added code */}
//         <div className="carousel-item">
//             <ScrollIntoView selector="#formRegister">
//               <img
//               src="/assets/2horizantal.png"
//                 className="d-md-block d-none w-100"
//                 alt="Slide 2"
//               />
//             </ScrollIntoView>
//           </div>

//           {/* <div className="carousel-item">
//             <ScrollIntoView selector="#formRegister">
//               <img
//                 // src="/assets/Banner 03.jpg"
//                 className="d-md-block d-none w-100"
//                 alt="Slide 3"
//               />
//             </ScrollIntoView>
//           </div> */}
//   {/* my added code */}


//         </div>
//         <button
//           className="carousel-control-prev"
//           type="button"
//           data-bs-target="#carouselExampleDesktop"
//           data-bs-slide="prev"
//         >
//           <span
//             className="carousel-control-prev-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Previous</span>
//         </button>
//         <button
//           className="carousel-control-next"
//           type="button"
//           data-bs-target="#carouselExampleDesktop"
//           data-bs-slide="next"
//         >
//           <span
//             className="carousel-control-next-icon" 
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Next</span>
//         </button>
//       </div>

//       <div
//         id="carouselExampleMobile"
//         className="carousel slide d-md-none d-block"
//         data-bs-ride="carousel"
//       >
//         <div className="carousel-indicators">
//           <button
//             type="button"
//             data-bs-target="#carouselExampleMobile"
//             data-bs-slide-to="0"
//             className="active"
//             aria-current="true"
//             aria-label="Slide 1"
//           ></button>
//           {/* <button
//             type="button"
//             data-bs-target="#carouselExampleMobile"
//             data-bs-slide-to="1"
//             aria-label="Slide 2"
//           ></button> */}

//           {/* added code */}
//   <button
//     type="button"
//     data-bs-target="#carouselExampleMobile"
//     data-bs-slide-to="1"
//     aria-label="Slide 2"
//   ></button>
//   <button
//     type="button"
//     data-bs-target="#carouselExampleMobile"
//     data-bs-slide-to="2"
//     aria-label="Slide 3"
//   ></button>
// {/* added code */}

//         </div>
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <img src="/assets/1landscapMobile.png" className="w-100" alt="Slide 1" />
//           </div>
//           {/* <div className="carousel-item">
//             <img src="/assets/BannerMobile2.webp" className="w-100" alt="Slide 2" />
//           </div> */}

// {/* added code */}
//           <div className="carousel-item3">
//             <img src="/assets/2verticalMob.png" className="w-100" alt="Slide 2" />
//           </div>
//           <div className="carousel-item4">
//             <img src="/assets/Banner 03.jpg" className="w-100" alt="Slide 3" />
//           </div>
// {/* added code */}

//         </div>
//         <button
//           className="carousel-control-prev"
//           type="button"
//           data-bs-target="#carouselExampleMobile"
//           data-bs-slide="prev"
//         >
//           <span
//             className="carousel-control-prev-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Previous</span>
//         </button>
//         <button
//           className="carousel-control-next"
//           type="button"
//           data-bs-target="#carouselExampleMobile"
//           data-bs-slide="next"
//         >
//           <span
//             className="carousel-control-next-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Next</span>
//         </button>
//       </div>
//     </>
//   );
// }

// export default Hero;
